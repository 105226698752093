import {Box, Card, CardContent, Grid, Typography} from "@material-ui/core"
import React, {useEffect, useState} from "react"
import * as queryString from "query-string"
import {CSSTransition} from "react-transition-group"
import {Layout} from "../../components/Layout"
import {MdLowerThird} from "../../components/i18n/TransMdTypo"
import {GrowGrid} from "../../components/Shared"
import logo from "../../data/images/recalbox-white.svg"
import {extractFromParams} from "../../api/Utils"

const LowerThird = () => {
  const [currentLine, setCurrentLine] = useState(0)
  const [bottomLines, setBottomLines] = useState([] as string[])
  const [newsLines, setNewsLines] = useState({newsUp: "Recalbox", newsDown: "News"})
  const [topLines, setTopLines] = useState([] as string[])
  const [time, setTime] = useState(10000)

  useEffect(() => {
    if (typeof window === "undefined") {
      return
    }
    const q = queryString.parseUrl(window.location.search)
    const timeout = parseInt(q.query.timeout as string) * 1000
    setTime(timeout || 1000)
    setNewsLines({newsUp: q.query.newsUp as string ?? "Recalbox", newsDown: q.query.newsDown as string ?? "News"})
    setBottomLines(extractFromParams(q.query.bottomLines))
    setTopLines(extractFromParams(q.query.topLines))
  }, [currentLine])

  useEffect(() => {
    const interval = setInterval(() => {
      setAppear(false)
      setTimeout(() => {
        setCurrentLine((currentLine + 1) % topLines.length)
        setAppear(true)
      }, 200)
    }, time)
    return () => clearInterval(interval)
  }, [currentLine, topLines, time])
  const [appear, setAppear] = useState(true)

  return (
    <Layout title={""} noNav hideCookieConsent>
      <Box marginLeft={3}>
      </Box>
      <Card>
        <CardContent>
          <Grid container wrap={"nowrap"} alignItems={"center"} alignContent={"center"} spacing={3}>
            <Grid item css={{textAlign: "center"}}>
              <CSSTransition classNames={"translateY-top"} in={appear} appear timeout={1000}>
                <Typography css={(theme) => ({
                  fontSize: "1.5em",
                  fontWeight: "bold",
                  color: theme.palette.text.secondary,
                  paddingTop: "0.3em"
                })}>{newsLines.newsUp}</Typography>
              </CSSTransition>
              <CSSTransition classNames={"translateY-bottom"} in={appear} appear timeout={1000}>
                <Typography css={(theme) => ({
                  fontSize: "2.5em",
                  fontWeight: 700,
                  color: theme.palette.secondary.main
                })}>{newsLines.newsDown}</Typography>
              </CSSTransition>
            </Grid>
            <GrowGrid item css={{paddingTop: "0px!important", paddingBottom: "0px!important", overflowX: "hidden"}}>
              {bottomLines[currentLine] !== "" ?
                <React.Fragment>
                  <CSSTransition key={`topLine${currentLine}`} classNames={"translateX-right"} in={appear} appear
                                 timeout={1000}>
                    <MdLowerThird fontSize={"3em"} fontWeight={500}>{topLines[currentLine]}</MdLowerThird>
                  </CSSTransition>
                  <CSSTransition key={`bottomLine${currentLine}`} classNames={"translateX-right"} in={appear} appear
                                 timeout={1000}>
                    <MdLowerThird fontSize={"2em"} fontWeight={500}>{bottomLines[currentLine]}</MdLowerThird>
                  </CSSTransition>
                </React.Fragment>
                :
                topLines[currentLine] !== "" &&
                <CSSTransition key={`bottomLine${currentLine}`} classNames={"translateX-right"} in={appear} appear
                               timeout={1000}>
                  <MdLowerThird fontSize={"3.5em"} fontWeight={500}>{topLines[currentLine]}</MdLowerThird>
                </CSSTransition>
              }
            </GrowGrid>
            {/*<GrowGrid item/>*/}
            <Grid item>
              <img css={{height: "80px", animation: appear ? "spin 1s" : ""}} src={logo}/>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

    </Layout>
  )
}

export default LowerThird
